import BaseApi from './base'

class BillingCalendar extends BaseApi {
  constructor () {
    super()
  }

  async getEvents(start_dt, end_dt) {
      const params = {start_dt:start_dt, end_dt:end_dt}
      return await this.auth({
          url: '/billing_calendar',
          method: 'GET',
          params:params
      })
  }

  async getEvent(id) {
      return await this.auth({
          url: `/billing_calendar/${id}`,
          method: 'GET'
      })
  }

  async addEvent(payload) {
      return await this.auth({
          url: '/billing_calendar',
          method: 'POST',
          data:payload
      })
  }

  async updateEvent(payload, id) {
      return await this.auth({
          url: `/billing_calendar/${id}`,
          method: 'PUT',
          data: payload
      })
  }

  async deleteEvent(id) {
      return await this.auth({
        url: `/billing_calendar/${id}`,
        method: 'DELETE',
      })
  }


}
export default new BillingCalendar()