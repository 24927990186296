<template>
<v-container class="pa-0 ma-0" fluid>
    <v-card flat class="pb-0 mb-0 pt-0" height="1000px">
            <div/>
            <v-col>
                <v-sheet>
                    <v-toolbar flat>
                        <v-btn
                        outlined
                        class = "mr-4"
                        color="grey darken-2"
                        @click="setToday">
                            Today
                        </v-btn>
                        <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev">
                            <v-icon small>
                            mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next">
                            <v-icon small>
                            mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                           {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-toolbar-title v-else>
                            {{ this.cal_title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                        height="40"
                        color="success"
                        @click="component='AddEvent', modal=true">
                        Add Billing Period
                        </v-btn>
                    </v-toolbar>
                </v-sheet>
            </v-col>
                <v-sheet height="825px"> 
                    <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    type="month"
                    :events="events"
                    event-start="start_date"
                    event-end="end_date"
                    event-name="period_name"
                    @click:date="component='AddEvent', modal=true"
                    @change="updateRange"
                    >
                    <template #[`event`]="{ event }">
                        <span @click="component='EventModal', modal=true, selectedEvent=event">
                            {{event.period_name}}
                        </span>
                    </template>
                    </v-calendar>
                </v-sheet>
        <Component :key="componentKey" :is="component" :value.sync="modal" :selectedEvent="this.selectedEvent"/>
    </v-card>
</v-container>
    
</template>


<script>
import BillingCalendar from '@/axios/billing-calendar'
import { displayAlert } from '@/mixins/displayAlert'

export default {
    data () {
        return {
            events: [],
            cal_title: '',
            selectedEvent: {},
            curr_month: null,
            curr_year: null,
            start_dt: null,
            end_dt: null,
            today: null,
            focus: '',
            start: null,
            end: null,
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            component: '',
            componentKey: 0,
            modal: false,
            calValue: '',

        }
    },
    name: 'BillingCalendar',

    mixins: [displayAlert],

    components: {
        EventModal: () => import('@/components/EventModal.vue'),
        AddEvent: () => import('@/components/AddEvent.vue'),
    },

    async created () {
        this.setUpDates()

    },

    watch: {
    modal: {
      handler (value) {
        this.componentKey += 1
        if (!value) {
          if(!this.$refs.calendar.value){
            this.calValue = this.$refs.calendar.start
          }
          else {
            this.calValue = this.$refs.calendar.value
          }
          let newDate = new Date(this.calValue)
          let month = newDate.getMonth() + 1
          let year = newDate.getFullYear()
          let last_day = new Date(year, month, 0).getDate()

          let start = `${year}-${month}-01`
          let end = `${year}-${month}-${last_day}`

          this.getEvents(start, end)
        }
      }
    }
  },

    mounted () {
        this.$refs.calendar.checkChange()
    },

    methods: {
        async setUpDates() {
            const d = new Date()
            let month = d.getMonth() + 1
            let year = d.getFullYear()
            this.setCalTitle(month, year)
            let last_day = new Date(year, month, 0).getDate()

            this.today = new Date().toISOString().slice(0, 10)
            this.start_dt = `${year}-${month}-01`
            this.end_dt =  `${year}-${month}-${last_day}`
            this.curr_month = month
            this.curr_year = year
            await this.getEvents(this.start_dt, this.end_dt)
        },

        showEvent ({ event }) {
            this.selectedEvent = event 
        },

        async getEvent(id) {
            await BillingCalendar.getEvent
        },
        async updateRange({ start, end }) {
            this.start = start;
            this.end = end;

            let yr = this.start.year;
            let mn = this.start.month;

            const mNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];

            this.title = mNames[mn-1] + ", " + yr;
            await this.getEvents(this.start.date, this.end.date)
        },

        async getEvents (start_dt, end_dt) {
            this.loading = true
            try {
                const res = await BillingCalendar.getEvents(start_dt, end_dt)
                if (res.data) {
                this.events = res.data
                }
            } catch (err) {
                this.handleError(err)
            } finally {
                this.loading = false
            }
            this.events.forEach(element=> {
                    element.color = this.getColor(element.id)
                })
        },

        getColor (id) {
            if (id >= 7) {
                return this.colors[id % 7]
            }
            else {
                return this.colors[id]
            }
        },

        prev() {
            this.$refs.calendar.prev()
            this.curr_month = this.curr_month - 1
            this.curr_year = this.$refs.calendar.start.year
        },

        next() {
            this.$refs.calendar.next()
            this.curr_month = this.curr_month + 1
            this.curr_year = this.$refs.calendar.start.year
        },

        viewDay({ date }) {
            this.focus = date
        },

        setToday() {
            this.focus = ''
        },

        setCalTitle (month, year) {
            const monthes = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            let title_month = monthes[month - 1]
            this.cal_title = `${title_month} ${year}`
        }
    }


}

</script>
